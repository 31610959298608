<template>
  <div>
    <base-loading v-if="!ready" />
    <v-container
      v-else
      fluid
    >
      <v-row
        class="pt-0 pb-0 py-0"
      >
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Unidades de Negócio"
            :value="String(dashboard.unidades)"
            icon="mdi-city-variant-outline"
            color="indigo darken-1"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            :action="unidades"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Regionais"
            :value="String(dashboard.regionais)"
            icon="mdi-map-marker-path"
            color="indigo darken-1"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            :action="regionais"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Administradores"
            :value="String(dashboard.administradores)"
            icon="mdi-account-star"
            color="indigo darken-2"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            elevation="3"
            :action="administradores"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Gestores"
            :value="String(dashboard.gestores)"
            icon="mdi-account-tie"
            color="indigo darken-3"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            elevation="3"
            :action="gestores"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Fornecedores"
            :value="String(dashboard.fornecedores)"
            icon="mdi-account-supervisor"
            color="blue darken-4"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            elevation="3"
            :action="fornecedores"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="4"
          class="pt-0 pb-0"
        >
          <material-stats-card
            title="Associados"
            :value="String(dashboard.associados)"
            icon="mdi-account-group"
            color="blue darken-4"
            sub-icon="mdi-clock-outline"
            sub-icon-color="info"
            sub-text="Até o momento"
            elevation="3"
            :action="associados"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import MaterialStatsCard from '../../components/base/MaterialStatsCard'
  import DashboardStore, { BOOTSTRAP } from '@/store/modules/dashboard'

  export default {
    name: 'DashboardAdmin',
    components: {
      MaterialStatsCard,
    },
    data () {
      return {}
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('dashboard', ['ready', 'dashboard']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('dashboard')) { this.$store.registerModule('dashboard', DashboardStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('dashboard', [BOOTSTRAP]),
      unidades () {
        this.$router.push({ path: 'unidadesnegocio' }).catch(() => {})
      },
      administradores () {
        this.$router.push({ path: 'administradores' }).catch(() => {})
      },
      gestores () {
        this.$router.push({ path: 'gestores' }).catch(() => {})
      },
      fornecedores () {
        this.$router.push({ path: 'fornecedores' }).catch(() => {})
      },
      associados () {
        this.$router.push({ path: 'associados' }).catch(() => {})
      },
      regionais () {
        this.$router.push({ path: 'regionais' }).catch(() => {})
      },
    },
  }
</script>
